@import '@aurora/shared-client/styles/_variables.pcss';

.lia-message {
  &-status-container {
    display: grid;
    grid-template-columns: auto auto 1fr;
    grid-template-areas: 'icon content link';
    overflow-wrap: break-word;
    word-break: break-word;
    margin-bottom: 25px;
    border: 1px solid var(--lia-bs-warning);
    padding: 13px 15px 13px 15px;
    border-left: 5px solid var(--lia-bs-warning);
    border-radius: var(--lia-bs-border-radius-sm);
    font-size: var(--lia-bs-font-size-sm);
    color: var(--lia-bs-gray-900);
    background-color: var(--lia-bs-white);
    align-items: center;

    @media (--lia-breakpoint-down-md) {
      margin: 25px 20px;
      align-items: flex-start;
    }

    &-forum {
      margin-bottom: 0;
      margin-top: 25px;
      @media (--lia-breakpoint-down-sm) {
        margin: 25px 0 0 0;
      }
    }
    &-message {
      max-width: var(--lia-one-column-narrow-width);
      margin: 25px auto 0;
    }

    &-v2 {
      &-forum {
        max-width: 700px;
        margin: 0 auto 25px;
        @media (--lia-breakpoint-down-sm) {
          margin: 0 0 25px 0;
        }
      }

      &-message {
        max-width: 700px;
        margin: 0 auto 25px;
      }
    }
  }
  &-link {
    display: inline-grid;
    text-align: left;
    padding: 0;
    margin-left: 20px;
    border: none;
    @media (--lia-breakpoint-down-md) {
      margin-left: 0;
    }
  }
}
