@import '@aurora/shared-client/styles/_variables.pcss';

.lia-action {
  display: flex;
  min-height: 48px;

  &-state-wrapper {
    display: flex;
    align-items: center;

    @media (--lia-breakpoint-down-md) {
      width: 100%;
      flex-direction: column;
    }
  }
  &-draft {
    &-edit {
      display: flex;
      flex-direction: row;
      width: 100%;
      align-items: center;
      justify-content: space-between;

      &-state {
        margin-right: 20px;

        @media (--lia-breakpoint-down-md) {
          margin-bottom: 20px;
          margin-right: 0;
        }
      }
      @media (--lia-breakpoint-down-md) {
        flex-direction: column;
        margin: 20px 0;
      }
    }
  }
  &-schedule {
    &-edit {
      display: flex;
      align-items: center;
      flex-direction: row;
      margin: auto;
      margin-left: 0;

      @media (--lia-breakpoint-down-md) {
        flex-direction: column;
        margin: 0;
      }
    }
    &-text {
      display: flex;
      margin-left: 10px;

      @media (--lia-breakpoint-down-md) {
        flex-direction: column;
        align-items: center;
        margin-bottom: 20px;
      }
    }
  }
  &-toolbar {
    @media (--lia-breakpoint-down-sm) {
      padding: 0 15px;
    }
  }

  @media (--lia-breakpoint-down-md) {
    width: 100%;
  }
}

.lia-toolbar {
  display: flex;
  gap: 10px;

  @media (--lia-breakpoint-down-md) {
    width: 100%;
    flex-direction: column;
  }
}

.lia-schedule-toolbar {
  &-links {
    display: flex;
    flex-direction: row;

    @media (--lia-breakpoint-down-md) {
      flex-direction: column;
    }
  }
  &-link {
    padding: 0;
    margin-left: 20px;

    @media (--lia-breakpoint-down-md) {
      margin: 0 0 20px;
    }
  }
}

.lia-edits-blocked-toolbar {
  display: flex;
  min-height: 48px;
  align-items: center;
}

.lia-un-mod-toolbar {
  min-height: 48px;
  align-items: center;
}

@media (--lia-breakpoint-down-md) {
  .lia-version-history {
    width: 100%;
    margin-bottom: 10px;
  }
  .lia-message-status {
    margin-bottom: 20px;
  }
}

@media (--lia-breakpoint-down-sm) {
  .lia-message-action-themed-container {
    padding: 0;
  }
}
